<template>
  <div
    style="
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    "
  >
    <!--    <div class="py-4 text-h4">Storage Form</div>-->
    <form id="createForm" class="pa-3 mx-3 row elevation-0">
      <div class="col-12 col-sm-6 h-75px">
        <v-combobox
          v-model="formData.order_ids"
          label="Orders"
          item-text="systemCode"
          item-value="orderId"
          :hide-no-data="!search"
          dense
          outlined
          multiple
          small-chips
          deletable-chips
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          hide-selected
          no-filter
          :error-messages="orderErrors"
          @input="$v.formData.order_ids.$touch()"
          @blur="$v.formData.order_ids.$touch()"
        >
          <template v-slot:no-data>
            <v-list-item> No data found! </v-list-item>
          </template>
        </v-combobox>
      </div>

      <div class="col-12 col-md-6 h-75px">
        <v-autocomplete
          v-model="formData.collection_address_id"
          label="Collection address"
          :items="collection_addresses"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="collection_address_idErrors"
          @input="$v.formData.collection_address_id.$touch()"
          @blur="$v.formData.collection_address_id.$touch()"
        >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <button class="btn btn-info pl-2" @click="createNewItem()">
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <!-- <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <button class="btn btn-info pl-2" @click="createNewItem()">
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->
        </v-autocomplete>
      </div>

      <!-- <div class="col-12 col-md-6 h-75px">
        <v-select
          v-model="formData.warehouse_id"
          label="Delivery address"
          :items="serverData.warehouses"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="warehouse_idErrors"
          @input="$v.formData.warehouse_id.$touch()"
          @blur="$v.formData.warehouse_id.$touch()"
        ></v-select>
      </div> -->
      <div class="col-sm-6 col-12">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              label="Collection date"
              readonly
              clearable
              dense
              outlined
              v-bind="attrs"
              v-on="on"
              :error-messages="collection_dateErrors"
              @input="$v.formData.collection_date.$touch()"
              @blur="$v.formData.collection_date.$touch()"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formData.collection_date"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="col-12 col-md-6 col-md-4 h-75px">
        <v-select
          v-model="formData.collection_time_id"
          label="Collection time"
          :items="serverData.collection_times"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="collection_time_idErrors"
          @input="$v.formData.collection_time_id.$touch()"
          @blur="$v.formData.collection_time_id.$touch()"
        ></v-select>
      </div>

      <div class="col-12 col-sm-6 col-md-4 h-75px">
        <v-select
          v-model="formData.vehicle_type_id"
          label="Vehicle type"
          :items="serverData.vehicle_types"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="vehicle_type_idErrors"
          @input="$v.formData.vehicle_type_id.$touch()"
          @blur="$v.formData.vehicle_type_id.$touch()"
        ></v-select>
      </div>
      <!-- <div class="col-12 col-sm-6 col-md-6 h-75px">
        <v-datetime-picker
          ref="datetime"
          label="Collection date and time"
          :textFieldProps="{
            outlined: true,
            dense: true,
            'prepend-icon': 'mdi-calendar',
            'error-messages': collection_datetimeErrors,
          }"
          :datePickerProps="{}"
          :timePickerProps="{}"
          v-model="formData.collection_datetime"
          @input="$v.formData.collection_datetime.$touch()"
          @blur="$v.formData.collection_datetime.$touch()"
        >
        </v-datetime-picker>
      </div> -->
      <div class="col-sm-6 col-md-4 col-12 h-75px">
        <v-select
          v-model="formData.package_type"
          label="Package type"
          item-text="text"
          item-value="index"
          :items="serverData.package_types"
          clearable
          outlined
          dense
        ></v-select>
      </div>
      <div class="col-12 col-sm-6 col-md-4 h-75px">
        <v-text-field
          v-model="formData.number_of_packages"
          :label="number_of_packages_label"
          type="number"
          @change="() => validateMinValue('number_of_packages', 0, 'formData')"
          min="0"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
      <div
        class="col-12 col-sm-6 col-md-4 h-75px"
        v-if="formData.package_type == '1'"
      >
        <v-text-field
          v-model="formData.total_number_of_boxes"
          label="Total number of boxes"
          type="number"
          @change="
            () => validateMinValue('total_number_of_boxes', 0, 'formData')
          "
          min="0"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <v-text-field
          class
          v-model="formData.weight"
          type="number"
          @change="() => validateMinValue('weight', 0, 'formData')"
          min="0"
          label="Weight"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <v-text-field
          class
          v-model="formData.volumetric_weight"
          type="number"
          @change="() => validateMinValue('volumetric_weight', 0, 'formData')"
          min="0"
          label="Volumetric weight"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
      <div class="col-12">
        <v-text-field
          class
          v-model="formData.comment"
          label="Comments"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
      <CollectionAddress
        ref="collectionAddressComponent"
        :serverData="serverData"
        :pageLoader="pageLoader"
        :setData="setDataOfAddress"
        :initData="initData"
        :customer_id="customer_id"
        :getStep2Data="getStep2Data"
      ></CollectionAddress>
      <!-- <DestinationAddress
        ref="destinationAddressComponent"
        :serverData="serverData"
        :pageLoader="pageLoader"
        :setData="setDataOfAddress"
        :initData="initData"
      ></DestinationAddress> -->

      <!-- <div class="col-12">
        <OrderDatatable
          :setSku="setSkus"
          :skus="formData.order_ids"
          :skuItem="serverData.orders"
          :clientId="userId"
          :isEditable="false"
        ></OrderDatatable>
      </div> -->
    </form>
    <v-main class="text-center mt-1 col-12 mb-4 elevation-0">
      <v-btn class="mx-1" color="primary" @click="submitCreateForm">
        submit
      </v-btn>
      <v-btn class="mx-1" color="red" dark @click="resetCreateForm">
        clear
      </v-btn>
      <v-btn class="mx-1" @click="goBack"> back </v-btn>
    </v-main>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import CollectionAddress from "@/own/components/shipping/pickupOrders/addressComponents/CollectionAddress";
// import DestinationAddress from "@/own/components/shipping/pickupOrders/addressComponents/DestinationAddress";
// import OrderDatatable from "@/own/components/shipping/pickupOrders/orderContainer/OrderDatatable";
import Swal from "sweetalert2";
import { getToken } from "@/core/services/jwt.service";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "CrossDockForm",
  mixins: [validationMixin, fieldValueValidation],
  components: {
    CollectionAddress,
    // DestinationAddress,
    // OrderDatatable,
  },
  props: [
    "setData",
    "actionFuntion",
    "serverData",
    "pageLoader",
    "initData",
    "userId",
    "customer_id",
    "getStep2Data",
  ],
  validations() {
    return {
      formData: {
        collection_date: { required },
        collection_time_id: { required },
        order_ids: { required },
        // warehouse_id: { required },
        vehicle_type_id: { required },
        collection_address_id: { required },
      },
    };
  },
  data: () => ({
    formData: {
      package_type: null,
      number_of_packages: null,
      collection_date: null,
      collection_time_id: null,
      order_ids: [],
      total_number_of_boxes: null,
      collection_address_id: null,
      // warehouse_id: null,
      weight: null,
      vehicle_type_id: null,
      volumetric_weight: null,
      comment: null,
    },
    menu1: false,
    search: null,
    count: null,
    entries: [],
    isLoading: false,
    doneTypingInterval: 1500,
    typingTimer: null,
    collection_addresses: [],
  }),
  beforeMount() {
    this.collection_addresses = this.serverData.collection_addresses;
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    createNewItem() {
      this.$nextTick(() => {
        this.$refs.collectionAddressComponent.toggleModal();
      });
    },
    async getData(value) {
      let data = { q: value };
      // if (this.userId) data.client = this.userId;
      if (this.customer_id) data.client = this.customer_id;

      await fetch(`${process.env.VUE_APP_BASE_URL}/orders/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.orders;
        })
        .finally(() => (this.isLoading = false));
    },
    setSkus(item) {
      this.formData.order_ids = [...item];
    },
    goBack() {
      this.actionFuntion("back");
    },
    setDataOfAddress(data, type) {
      this.formData[`${type}`] = data;
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    submitCreateForm() {
      this.$v.$touch();
      // const comp1 = this.$refs.collectionAddressComponent.submitData();
      // const comp2 = this.$refs.destinationAddressComponent.submitData();
      if (this.$v.$invalid) {
        return;
      }
      // if (!comp1) {
      //   return;
      // }
      // if (!comp2) {
      //   return;
      // }
      if (!this.formData.order_ids.length > 0) {
        Swal.fire({
          title: "Info",
          text: `Orders are required!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      this.pageLoader(true);
      // let data = JSON.stringify(this.entCreate);
      const data = {
        ...this.formData,
      };
      const [year, month, day] = this.formData.collection_date.split("-");
      data.collection_date = `${day}-${month}-${year}`;
      // data.collection_datetime = this.dateFormatter(
      //   this.formData.collection_datetime
      // );
      // console.log(data);
      let orders = data.order_ids.map((order) => {
        return order.orderId;
      });
      data.order_ids = orders;
      // data.collection_address_id = this.formData.collection_address_id.index;
      this.setData(data);
      this.actionFuntion();
      // this.resetCreateForm();
    },
    resetCreateForm() {
      // console.log("reset create form");
      this.$v.$reset();
      this.collection_addresses = this.serverData.collection_addresses;

      if (this.initData) {
        // try {
        let copy = { ...this.serverData.pickup_order };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.pickup_order).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
            }
          });

          if (
            this.serverData.pickup_order.collection_date &&
            this.serverData.pickup_order.collection_date != ""
          ) {
            const [day, month, year] =
              this.serverData.pickup_order.collection_date.split("-");
            this.formData.collection_date = `${year}-${month}-${day}`;
          }
          // this.$nextTick(() => {

          // });
        });
        // this.$refs.collectionAddressComponent.resetData();
        // this.$refs.destinationAddressComponent.resetData();
        // } catch (e) {
        // console.error(e);

        // this.formData = {
        //   package_type: null,
        //   number_of_packages: null,
        //   collection_datetime: null,
        //   order_ids: [],
        // };
        // }
      } else {
        // this.$nextTick(() => {
        // this.$refs.collectionAddressComponent.resetData();
        // this.$refs.destinationAddressComponent.resetData();
        // });
        this.formData = {
          package_type: null,
          number_of_packages: null,
          collection_date: null,
          collection_time_id: null,
          order_ids: [],
          total_number_of_boxes: null,
          collection_address_id: null,
          vehicle_type_id: null,
          // warehouse_id: null,
          weight: null,
          volumetric_weight: null,
          comment: null,
        };
        // this.$refs.datetime.clearHandler();
      }
    },
    dateMaker(data) {
      let date = new Date(data);
      return date;
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.formData.collection_date);
    },
    items: function () {
      return this.entries;
    },
    orderErrors: function () {
      return this.handleFormValidation("order_ids", this);
    },
    // warehouse_idErrors: function () {
    //   return this.handleFormValidation("warehouse_id", this);
    // },
    collection_address_idErrors: function () {
      return this.handleFormValidation("collection_address_id", this);
    },
    vehicle_type_idErrors: function () {
      return this.handleFormValidation("vehicle_type_id", this);
    },
    collection_dateErrors() {
      return this.handleFormValidation("collection_date", this);
    },
    collection_time_idErrors() {
      return this.handleFormValidation("collection_time_id", this);
    },
    number_of_packages_label() {
      return this.formData.package_type == "2"
        ? "Number of boxes"
        : "Number of pallet";
    },
  },
  watch: {
    // "formData.collection_date": {
    //   handler() {
    //     this.dateFormatted = this.formatDate(this.formData.collection_date);
    //   },
    //   deep: true,
    // },
    search(val) {
      clearTimeout(this.typingTimer);
      this.isLoading = true;
      if (val) {
        this.typingTimer = setTimeout(() => {
          this.getData(val.trim());
        }, this.doneTypingInterval);
      } else {
        this.isLoading = false;
        return;
      }
    },
  },
};
</script>
